$dark: #403B30;

html, body {
    width: 100vw !important;
}

.first-container {
    background: url('../Images/skyline.jpeg') center center no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.dove {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 8%;
  margin-left: 20px;
  margin-top: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    float: right;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    display: table;
}

.main-title {
    display: block;
    text-align: center;
    color: white;
    font-family: 'Bungee Inline', cursive;
    font-size: 4rem;
    width: 100vw;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
}

.name {
    margin-top: 20vh;
}

.scroll-container {
    margin-top: 20%;
}



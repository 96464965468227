$goldenrod: #DAA520;
$dark: #403B30;

$gold: #FFD700;
$gold-gradient-01: linear-gradient(top, #8f6B29, #FDE08D, #DF9F28);
$gold-gradient-webkit-01: -webkit-linear-gradient(top, #8f6B29, #FDE08D, #DF9F28);
$box-shadow: 10px 10px 20px #888888;
$main-font:'Libre Baskerville', serif;

.second-container {
    position: relative;
  background: $dark;
  z-index: 100;
  margin-top: 100vh;
  width: inherit;
  padding-top: 100px;
  min-height: 100vh;
}

.water-video{
    width: 100%;
    height: auto;
    padding: 5px;
}


.motto > p {
        color: $goldenrod;
        font-family: $main-font;
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        margin: 80px 30px 0 30px;
        padding: 20px;
    
}
$goldenrod: #DAA520;
$dark: #33302A;



.contact {
    background: url('../Images/buildings.jpeg') center center no-repeat;
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    position: relative;
    z-index: 10;
}

.contact-card{
    background: $dark;

    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 25%;
    color: $goldenrod;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 100%;

    h2 {
        margin: 0 10% 10px 10%;
        
    }

    h3,h4 {
        margin-top: 20px;
    }

}

footer {
    background: $dark;
    z-index: 100;
    position: relative;
    min-height: 50px;

    p {
        color: white;
        float: left;
        margin-left: 10px;
    
    }
}
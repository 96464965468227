
.third-section{
    background: url('../Images/terrace.jpg') center center no-repeat;
    background-size: cover;
    position: relative;
    z-index: 100;
    width: 100vw;
    height: 100vh;


}
